import axios from 'axios';
import { getDocumentUrl, getDocuments } from '@/api';
import { Document, IDocumentParams } from '@/models';
import { downloadData } from '@/util/funcs';

const getDocumentsData = async (params?: IDocumentParams) => {
  const documents = await getDocuments(params);
  if (documents.totalDocuments > 0) {
    return documents;
  }
};

const downloadDocument = async (doc:Document) => {
  const downloadUrl = await getDocumentUrl(doc);
  const label = doc.title;
  const urlParts = doc.fileLocation.split('.');
  const extension = urlParts[urlParts.length - 1];
  downloadData(downloadUrl, label, extension);
};

const viewDocument = async (document:Document):Promise<string> => {
  const url = await getDocumentUrl(document);
  return url;
};

export { getDocumentsData, downloadDocument, viewDocument };
