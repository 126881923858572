<template>
  <section class="animate-pulse">
    <ul class="flex space-x-2">
        <li
        class="bg-wrh-gray-extralight
        py-5 w-xs text-center rounded-t-lg flex"
        >
        <span class="bg-white h-2 w-10 m-auto"></span>
        </li>
        <li
        class="bg-wrh-gray-extralight
        py-3 w-xs text-center rounded-t-lg flex"
        >
        <span class="bg-white h-2 w-10 m-auto"></span>
        </li>
    </ul>
    <div class="p-6 pb-0 bg-white rounded-b-xl mb-6 relative">
      <div class="space-y-2">
        <div class="bg-wrh-gray-extralight h-3 w-1/2 block"></div>
        <div>
          <span class="bg-wrh-gray-extralight h-2 w-2 inline-block mr-2"></span>
          <span class="bg-wrh-gray-extralight h-2 w-1/4 inline-block"></span>
        </div>
      </div>
      <div class="flex items-center justify-center flex-col h-77">
        <span class="bg-wrh-gray-extralight h-[34px] w-[102px] rounded mb-2 block"></span>
        <div class="bg-wrh-gray-extralight h-2 w-1/3"></div>
      </div>
    </div>
  </section>
</template>
