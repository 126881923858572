import { format } from 'date-fns';
import { Issues } from '@/constants';
import { IHomeIssue } from '@/models';

const convertFileToBase64 = (files: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
  reader.readAsDataURL(files);
});

export function addDays(date: Date, days: number) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

export function addMonths(date: Date, months: number) {
  const copy = new Date(Number(date));
  copy.setMonth(date.getMonth() + months);
  return copy;
}

const checkSixtyPassed = (date: string) => {
  const ownerShipDate = new Date(date);
  const todayDate = new Date();
  const newDate = addDays(ownerShipDate, 60);
  if (newDate.getTime() < todayDate.getTime()) {
    return true;
  }
  return false;
};

const checkElevenPassed = (date: string) => {
  const ownerShipDate = new Date(date);
  const todayDate = new Date();
  const newDate = addMonths(ownerShipDate, 11);
  if (newDate.getTime() < todayDate.getTime()) {
    return true;
  }
  return false;
};

const checkIfOwnershipDatePassed = (date:string) => {
  const ownerShipDate = new Date(date);
  const todayDate = new Date();
  if (todayDate.getTime() > ownerShipDate.getTime()) {
    return true;
  }
  return false;
};

const checkInitialState = (selectedDetails: any) => {
  if (selectedDetails.value && selectedDetails.value.issues.length > 0) {
    return false;
  }
  return true;
};

const dateGenerator = (dates: string, days: number) => {
  const date = new Date(dates);
  const newDate = addDays(date, days);
  return format(newDate, 'MM/dd/yyyy');
};

const monthGenerator = (dates: string, months: number) => {
  const date = new Date(dates);
  const newDate = addMonths(date, months);
  return format(newDate, 'MM/dd/yyyy');
};

const maxTotalIssuePassed = (issues: IHomeIssue[]) => {
  if (issues === undefined || issues?.length < Issues.maxIssuesCount) {
    return false;
  }
  return true;
};

const sixtyWarrantyDatePassed = (dates: string) => {
  const date = new Date(dates);
  const todayDate = new Date();
  const newDate = addDays(date, 81);
  newDate.setHours(23, 59, 59, 59);
  if (todayDate.getTime() > newDate.getTime()) {
    return true;
  }
  return false;
};

const elevenWarrantyDatePassed = (dates: string) => {
  const date = new Date(dates);
  const todayDate = new Date();
  const newDate = addMonths(addDays(date, 21), 11);
  newDate.setHours(23, 59, 59, 59);
  if (todayDate.getTime() > newDate.getTime()) {
    return true;
  }
  return false;
};

export {
  convertFileToBase64, checkSixtyPassed, checkInitialState, checkElevenPassed, checkIfOwnershipDatePassed, dateGenerator, maxTotalIssuePassed,
  monthGenerator, sixtyWarrantyDatePassed, elevenWarrantyDatePassed,
};
